import * as Yup from "yup";
import { parsePhoneNumber, isValidPhoneNumber } from "react-phone-number-input";
import zipCodeValidator from "src/utils/zipCodeValidator";

const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .matches(
      /^[A-Za-zÀ-ÖØ-öø-ÿ\s'-]{2,}$/u,
      "Invalid first name. Cannot include special characters or numbers."
    )
    .required("Required"),
  phoneNumber: Yup.string()
    .required("Required")
    .test(
      "validPhone",
      "Invalid Phone Number. Please enter a valid phone number",
      function (phone) {
        if (!phone) {
          return false;
        }

        const isUs = parsePhoneNumber(phone).country === "US";

        if (!isUs) {
          return false;
        }
        return isValidPhoneNumber(phone);
      }
    ),
  email: Yup.string()
    .email("Invalid Email. Please enter a valid email address")
    .required("Required"),
  zipCode: Yup.string()
    .required("Required")
    .min(5, "Must be 5 digits")
    .max(5, "Must be 5 digits")
    .matches(/^[0-9]+$/, "Must be numeric")
    .test(
      "validZip",
      "Invalid zip code. Please enter a valid zip code",
      function (zipCode) {
        if (!zipCode) {
          return false;
        }

        const isUSZipCode = zipCodeValidator(zipCode);

        return isUSZipCode;
      }
    ),

  journeyPathStatus: Yup.object().shape({
    id: Yup.number().required("Required").typeError("Required"),
  }),

  // RE Status
  atReStatus: Yup.object().shape({
    id: Yup.number().required("Required").typeError("Required"),
    label: Yup.string().required("Required"),
    notAtReReasons: Yup.array().when(["label"], {
      is: (atReStatusLabel) => {
        return atReStatusLabel === "No";
      },
      then: Yup.array().min(1).required("Required"),
      otherwise: Yup.array().optional(),
    }),
    otherNotAtReReasonContent: Yup.string().when(["notAtReReasons"], {
      is: (notAtReReasons) => {
        return notAtReReasons?.some((reason) => reason?.label === "Other");
      },
      then: Yup.string().required("Required"),
      otherwise: Yup.string().optional(),
    }),
  }),

  // Event Journey:
  journeyEvent: Yup.object().when("atReStatus", {
    is: (atReStatus) => {
      return atReStatus?.label === "Yes";
    },
    then: Yup.object().shape({
      id: Yup.number().required("Required").typeError("Required"),
      label: Yup.string().required("Required"),
      otherContent: Yup.string().when(["label"], {
        is: (journeyEventLabel) => {
          const journeyEventCheck = journeyEventLabel === "Other";
          return journeyEventCheck;
        },
        then: Yup.string().required("Required"), // Directly validate otherContent
        otherwise: Yup.string().optional(),
      }),
    }),
  }),

  // Trying to Conceive Duration:
  ttcDuration: Yup.object().when(["journeyPathStatus"], {
    is: (journeyPathStatus) => {
      return journeyPathStatus?.label === "Trying to Conceive";
    },
    then: Yup.object().shape({
      id: Yup.number()
        .required("Duration is required")
        .typeError("Duration must be a number"),
    }),
    otherwise: Yup.object().shape({
      id: Yup.number().nullable().optional(),
    }),
  }),

  // Demographics:
  ageRange: Yup.object().shape({
    id: Yup.number().required("Required").typeError("Required"),
  }),

  genderIdentity: Yup.object().shape({
    id: Yup.number().required("Required").typeError("Required"),
  }),

  relationshipStatusId: Yup.number().required("Required").typeError("Required"),

  atObgynStatusId: Yup.number().required("Required").typeError("Required"),

  hasInsurance: Yup.object().shape({
    id: Yup.number().required("Required").typeError("Required"),
  }),

  hasInsuranceBenefitId: Yup.number().when("hasInsurance", {
    is: (hasInsurance) => {
      return hasInsurance?.label === "Yes";
    },
    then: Yup.number().required("Required").typeError("Required"),
    otherwise: Yup.number().optional().nullable(),
  }),
  heightFeet: Yup.number().required("Required").typeError("Required"),
  heightInches: Yup.number().required("Required").typeError("Required"),
  // Weight, matches any digit between 0-9 that is 2-3 characters long
  weight: Yup.number()
    .min(50, "Invalid weight")
    .max(500, "Invalid weight")
    .required("Required")
    .typeError("Required"),

  ethnicIdentities: Yup.array()
    .min(1, "Must select at least one")
    .required("Required")
    .typeError("Required"),

  referralId: Yup.number().required("Required").typeError("Required"),
  otherReferral: Yup.string().when("source", {
    is: (referralId) => {
      return referralId === 9;
    },
    then: Yup.string().required("Required"),
    otherwise: Yup.string().optional(),
  }),
  // Optin:
  optinId: Yup.number().required("Required").typeError("Required"),
  // Accept Terms and Conditions:
  tAndCId: Yup.number()
    .required("Required")
    .typeError("Required")
    .test("isAccepted", "Must accept", (val) => val === 1),
  recaptcha: Yup.string().required("Required"),
});

export default validationSchema;
