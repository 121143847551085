import { useContext } from "react";
import { OptionContext } from "src/context/OptionContext";
import { Button, Stack, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Formik } from "formik";
import * as Yup from "yup";
import MultiSelect from "src/components/common/MultiSelect";
import { isEqual } from "lodash";
import SingleSelectAutocomplete from "src/components/common/SingleSelectAutocomplete";

const validationSchema = Yup.object().shape({
  atReStatusId: Yup.string().required("Required"),
  notAtReReasons: Yup.array().when("atReStatusId", {
    is: (id) => id === 2,
    then: Yup.array().required("Required"),
  }),
  otherNotAtReReasonContent: Yup.string().when("notAtReReasons", {
    is: (value) => value.includes("other"),
    then: Yup.string().required("Required"),
  }),
});

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%",
  },
}));

const AtReStatusForm = ({
  status: initialStatus,
  setEditStatusType,
  handleSubmit,
}) => {
  const { atReStatusOptions, notAtReReasonOptions } = useContext(OptionContext);
  const classes = useStyles();

  const initialNotAtReReasons = initialStatus?.notAtReReasons || [];
  const initialOtherNotAtReReasonContent =
    initialNotAtReReasons?.find((reason) => reason.label === "Other")
      ?.otherNotAtReReasonContent || "";

  const initialValues = {
    type: "at-re-status",
    atReStatusId: initialStatus?.id || null,
    notAtReReasons: initialNotAtReReasons,
    otherNotAtReReasonContent: initialOtherNotAtReReasonContent,
  };

  return (
    <Formik
      validationSchema={validationSchema}
      enableReinitialize
      validateOnBlur={false}
      validateOnChange={false}
      initialValues={initialValues}
      resetForm={{ values: initialValues }}
      onSubmit={(payload, { resetForm }) => handleSubmit(payload, resetForm)}
    >
      {({
        values,
        errors,
        setFieldError,
        handleSubmit,
        setFieldValue,
        isValid,
      }) => {
        const notAtRe = values.atReStatusId === 2;
        const hasOtherNotAtReReason = values?.notAtReReasons?.find(
          (reason) => reason.label === "Other"
        );

        return (
          <form
            onSubmit={(e) => {
              handleSubmit(e);
            }}
            className={classes.form}
          >
            <Stack alignItems="flex-end" spacing={1} useFlexGap sx={{ my: 1 }}>
              <SingleSelectAutocomplete
                id="atReStatus"
                label="At Re Status"
                options={atReStatusOptions}
                value={values.atReStatusId}
                onChange={(value) => {
                  setFieldValue("atReStatusId", value);
                  setFieldValue("notAtReReasons", []);
                  setFieldValue("otherNotAtReReasonContent", "");
                  setFieldError("atReStatusId", undefined);
                }}
              />
              {notAtRe && (
                <MultiSelect
                  id="notAtReReasons"
                  label="Not At Re Reasons"
                  options={notAtReReasonOptions}
                  values={values.notAtReReasons}
                  setValues={(values) => {
                    setFieldValue("notAtReReasons", values);
                    if (!hasOtherNotAtReReason) {
                      setFieldValue("otherNotAtReReasonContent", "");
                    }
                    setFieldError("notAtReReasons", undefined);
                  }}
                />
              )}
              {hasOtherNotAtReReason && (
                <TextField
                  fullWidth
                  name="otherNotAtReReasonContent"
                  label="Other Not At Re Reason"
                  variant="outlined"
                  value={values.otherNotAtReReasonContent}
                  onChange={(e) => {
                    setFieldValue("otherNotAtReReasonContent", e.target.value);
                    setFieldError("otherNotAtReReasonContent", undefined);
                  }}
                  error={Boolean(errors.otherNotAtReReasonContent)}
                />
              )}
              <Stack direction="row" spacing={1} alignItems="center">
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={() => setEditStatusType(null)}
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  disabled={Boolean(!isValid || isEqual(initialValues, values))}
                  type="submit"
                >
                  Save
                </Button>
              </Stack>
            </Stack>
          </form>
        );
      }}
    </Formik>
  );
};

export default AtReStatusForm;
