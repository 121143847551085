import axios from "src/utils/axios";
import { createContext, useEffect, useReducer, useContext } from "react";
import { toast } from "react-toastify";
import { UserContext } from "src/context/UserContext";

const initialOptions = {
  ageRangeOptions: [],
  activePhysicianOptions: [],
  currentTreatmentOptions: [],
  threadFlagOptions: [],
  agentOptions: [],
  journeyEventOptions: [],
  tagOptions: [],
  topicOptions: [],
  subtopicOptions: [],
  notAtReReasonOptions: [],
  noReReasonOptions: [],
  stoppedReReasonOptions: [],
  journeyPathStatusOptions: [],
  pregnantStatusOptions: [],
  atReStatusOptions: [],
  atObgynStatusOptions: [],
  coachOptions: [],
  journeyUpdateEventOptions: [],
  inactiveReasonOptions: [],
};

const optionReducer = (state, action) => {
  switch (action.type) {
    case "SET_CRM_OPTIONS":
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};

export const OptionContext = createContext();

const OptionContextProvider = (props) => {
  const userState = useContext(UserContext);
  const [options, optionDispatch] = useReducer(optionReducer, initialOptions);

  // GET PRIVATE OPTIONS:
  useEffect(() => {
    if (!userState.agent) return;
    (async () => {
      try {
        const { data: options } = await axios.get("/api/crm/options");

        if (options.length === 0) {
          throw new Error("No options found");
        }

        options.coachOptions = [
          { id: null, name: "-" },
          ...options.coachOptions,
        ];

        optionDispatch({
          type: "SET_CRM_OPTIONS",
          payload: options,
        });
      } catch (error) {
        toast.error("Error getting options");
        console.error("Error getting options", error.message);
      }
    })();
  }, [userState.agent]);

  return (
    <OptionContext.Provider value={options}>
      {props.children}
    </OptionContext.Provider>
  );
};

const OptionContextConsumer = OptionContext.Consumer;

export { OptionContextProvider, OptionContextConsumer };
