import React from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const ConfirmationModal = ({
  isModalOpen,
  handleSetConfirmationModal,
  handleSendMessageConfirmation,
  content,
  ctaText,
}) => {
  return (
    <Dialog
      open={isModalOpen}
      onClose={() => handleSetConfirmationModal(false)}
    >
      <DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => handleSetConfirmationModal(false)}
          sx={{
            position: "absolute",
            top: 2,
            right: 4,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <DialogContentText
          sx={{
            py: 2,
          }}
        >
          {content}
        </DialogContentText>
        <DialogActions>
          <Button
            aria-label="cancel"
            variant="outlined"
            color="primary"
            onClick={() => handleSetConfirmationModal(false)}
          >
            Cancel
          </Button>
          <Button
            aria-label="send message confirmation"
            color="primary"
            variant="contained"
            autoFocus
            onClick={handleSendMessageConfirmation}
          >
            {ctaText}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

ConfirmationModal.propTypes = {
  isModalOpen: PropTypes.bool,
  handleSetConfirmationModal: PropTypes.func,
  handleSendMessageConfirmation: PropTypes.func,
  content: PropTypes.string,
  ctaText: PropTypes.string,
};

ConfirmationModal.defaultProps = {
  isModalOpen: false,
};

export default ConfirmationModal;
