import { useState, useContext } from "react";
import { Box, Button, Grid, TextField, Autocomplete } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Formik } from "formik";
import * as Yup from "yup";
import { toInteger } from "lodash";
import { OptionContext } from "src/context/OptionContext";

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    gap: theme.spacing(1),
  },
}));

const validationSchema = Yup.object().shape({
  inactiveReason: Yup.object().required("Please select a reason"),
});

const initialValues = {
  inactiveReason: null,
};

const InactiveReasonForm = ({ onSubmit, onCancel }) => {
  const [open, setOpen] = useState(false);
  const { inactiveReasonOptions } = useContext(OptionContext);
  const { buttonContainer } = useStyles();

  const handleSubmit = ({ inactiveReason }) => {
    onSubmit(inactiveReason);
  };

  return (
    <Box pb={1}>
      <Formik
        validationSchema={validationSchema}
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={initialValues}
        onSubmit={(values) => handleSubmit(values)}
      >
        {({
          values,
          errors,
          isValid,
          setFieldValue,
          setFieldError,
          handleSubmit,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Grid
                container
                spacing={1}
                justifyContent="space-between"
                alignItems="flex-end"
              >
                <Grid item xs={12}>
                  <Autocomplete
                    open={open}
                    onOpen={() => {
                      setOpen(true);
                    }}
                    onClose={() => {
                      setOpen(false);
                    }}
                    id="inactiveReasons"
                    name="inactiveReasons"
                    options={inactiveReasonOptions}
                    disableClearable
                    value={values.inactiveReason}
                    isOptionEqualToValue={(option, value) =>
                      option?.id === value?.id || option?.id === null
                    }
                    getOptionLabel={(option) => option.label}
                    onChange={(e) => {
                      setFieldValue("inactiveReason", {
                        id: toInteger(e.currentTarget.dataset.optionIndex) + 1,
                        label: e.target.textContent,
                      });
                      setFieldError("inactiveReason", undefined);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Deactivate Reason"
                        variant="outlined"
                        error={errors.inactiveReason}
                        placeholder="Please select a reason for deactivation"
                      />
                    )}
                  />
                </Grid>
                <Grid
                  container
                  item
                  justifyContent="flex-end"
                  className={buttonContainer}
                  xs={12}
                >
                  <Grid item xs={3}>
                    <Button
                      fullWidth
                      color="primary"
                      variant="outlined"
                      onClick={onCancel}
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item xs={3}>
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={!isValid}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default InactiveReasonForm;
