import { createContext, useReducer, useEffect } from "react";
import axios from "src/utils/axios";

const initialFilters = {
  // text filters:
  phoneNumber: "",
  clientId: "",
  displayNameKeyword: "",
  // dropdown filters:
  agent: { id: null, name: "-" },
  ageRangeId: null,
  activePhysicianIds: [],
  currentTreatmentIds: [],
  tagIds: [],
  // checkbox filters:
  isActive: true,
  isSeeingRe: null,
  isQualified: null,
  isEngaged: null,
  hasInsurance: null,
  hasBmiUnder40: null,
  hasVirtualSession: null,
  hasBookedVirtualSession: null,
  hasCompletedVirtualSession: null,
  hasCompletedIntakeForm: null,
  hasCompletedFollowUpForm: null,
  hasNotReceivedCoachMessage: null,
  followUpDateSort: "ASC",
  page: 1,
};

const getSessionFiltersStorage = () => {
  const filterState = sessionStorage.getItem("filterState");
  if (filterState) {
    return JSON.parse(filterState);
  }
  return null;
};

const setSessionFiltersStorage = (filters) => {
  sessionStorage.setItem("filterState", JSON.stringify(filters));
};

const removeSessionFiltersStorage = () => {
  sessionStorage.removeItem("filterState");
};

const getClients = async (body) => {
  try {
    Object.keys(body).forEach((key) => {
      // Empty strings should be null on body
      if (body[key] === "") {
        body[key] = null;
      }
      // False values should be null on body
      if (body[key] === false) {
        body[key] = null;
      }
    });

    body.agentId = body.agent.id;

    const { data } = await axios.post(`/api/clients/query`, body);
    return data;
  } catch (error) {
    console.log("error", error);
    throw error;
  }
};

const chatReducer = (state, action) => {
  switch (action.type) {
    case "SET_IS_LOADING":
      return {
        ...state,
        isLoading: action.payload,
      };
    case "SET_FILTERS":
      return {
        ...state,
        filters: action.payload,
      };
    case "SET_FOLLOW_UP_DATE_SORT":
      return { ...state, followUpDateSort: action.payload };
    case "SET_PAGE":
      return { ...state, page: action.payload };
    case "SET_CLIENTS_RESULT":
      return { ...state, clientsResult: action.payload };
    case "SET_CLIENTS_ERROR":
      return { ...state, clientsError: action.payload };
    case "RESET_FILTERS":
      return { ...state, filters: initialFilters };
    default:
      return state;
  }
};

const ClientsContextProvider = (props) => {
  // Dyanmic initial state needs to rerender with context
  const initialState = {
    clientsResult: {
      clients: [],
      total: 0,
    },
    clientsError: null,
    isLoading: false,
    // Searchables
    filters: getSessionFiltersStorage() || initialFilters,
  };

  const [clientsState, dispatch] = useReducer(chatReducer, initialState);

  const setFilters = (filters) => {
    const newFilters = { ...clientsState.filters, ...filters, page: 1 };
    setSessionFiltersStorage(newFilters);
    dispatch({ type: "SET_FILTERS", payload: newFilters });
  };

  const setPage = (newPage) => {
    const newFilters = { ...clientsState.filters, page: newPage };
    setSessionFiltersStorage(newFilters);
    dispatch({ type: "SET_FILTERS", payload: newFilters });
  };

  const setFollowUpDateSort = (newSortState) => {
    const newFilters = {
      ...clientsState.filters,
      followUpDateSort: newSortState,
    };
    setSessionFiltersStorage(newFilters);
    dispatch({ type: "SET_FILTERS", payload: newFilters });
  };

  const resetFilters = () => {
    removeSessionFiltersStorage();
    dispatch({ type: "RESET_FILTERS" });
  };

  const handleGetClients = async () => {
    try {
      dispatch({ type: "SET_IS_LOADING", payload: true });
      const { clients, total } = await getClients({
        ...clientsState.filters,
      });
      dispatch({ type: "SET_CLIENTS_RESULT", payload: { clients, total } });
    } catch (error) {
      dispatch({ type: "SET_CLIENTS_ERROR", payload: error });
    } finally {
      dispatch({ type: "SET_IS_LOADING", payload: false });
    }
  };

  const value = {
    ...clientsState,
    setFilters,
    setPage,
    setFollowUpDateSort,
    resetFilters,
    handleGetClients,
  };

  useEffect(() => {
    handleGetClients();
  }, [clientsState.filters, clientsState.page, clientsState.followUpDateSort]);

  return (
    <ClientsContext.Provider value={value}>
      {props.children}
    </ClientsContext.Provider>
  );
};

export const ClientsContext = createContext();

const ClientsContextConsumer = ClientsContext.Consumer;

export { ClientsContextProvider, ClientsContextConsumer };
