import { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import {
  Autocomplete,
  Box,
  Avatar,
  TextField,
  InputAdornment,
  Typography,
} from "@mui/material";
import axios from "src/utils/axios";
import { toast } from "react-toastify";
import { OptionContext } from "src/context/OptionContext";

const nullAgent = { id: null, name: "-" };

const AgentSelect = ({
  initialAgent,
  label,
  clientId,
  handleSetAgent,
  ...props
}) => {
  const { coachOptions } = useContext(OptionContext);
  const [open, setOpen] = useState(false);
  const [agent, setAgent] = useState(nullAgent);

  const handleSelect = (newValue) => {
    if (clientId) {
      handleSaveNewProvider(newValue);
    } else {
      setAgent(newValue);
      handleSetAgent(newValue);
    }
  };

  const handleSaveNewProvider = async (newOption) => {
    try {
      await axios.put(`/api/clients/${clientId}`, {
        agentId: newOption.id,
      });

      setAgent(newOption);
    } catch (error) {
      setAgent(nullAgent);
      toast.error(`Error updating agent`);
      throw error;
    }
  };

  useEffect(() => {
    if (coachOptions?.length === 0) return;
    if (initialAgent) {
      setAgent(initialAgent);
    } else {
      setAgent(nullAgent);
    }
  }, [initialAgent, coachOptions]);

  return (
    <Autocomplete
      {...props}
      fullWidth
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      disableClearable
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
      value={agent}
      getOptionLabel={(option) => option?.name}
      options={coachOptions}
      onChange={(event, newValue) => {
        handleSelect(newValue);
      }}
      renderOption={(params, option) => {
        return (
          <Box
            key={params.id}
            sx={{
              display: "flex",
              alignItems: "center",
              m: 1,
              gap: 1,
            }}
            {...params}
          >
            <Box>
              <Avatar src={option?.avatarLink} />
            </Box>
            <Typography variant="body1">{option?.name}</Typography>
          </Box>
        );
      }}
      renderInput={(params) => {
        return (
          <TextField
            label={label || "Coach"}
            {...params}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <Avatar src={agent?.avatarLink} />
                </InputAdornment>
              ),
            }}
            variant="outlined"
            sx={{
              "& label": {
                zIndex: "auto",
              },
            }}
          />
        );
      }}
    />
  );
};

AgentSelect.propTypes = {
  initialAgent: PropTypes.object,
  label: PropTypes.string,
  clientId: PropTypes.string,
  handleSetAgent: PropTypes.func,
};

export default AgentSelect;
