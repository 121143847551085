import { HelpOutline as QuestionMarkIcon } from "@mui/icons-material";
import RadioSelect from "./RadioSelect";
import { Typography, RadioGroup, Box } from "@mui/material";
import ToolTip from "src/components/common/ToolTip";
import PropTypes from "prop-types";

const SingleRadioSelect = ({
  heading,
  options,
  value,
  onChange,
  subHeadingEl,
  hasError,
  onFocus,
  tooltip,
  hiddenOptionIds,
}) => {
  const handleChange = (e) => {
    const { value } = e.target;
    const option = options.find((option) => option.id === parseInt(value, 10));
    onChange(option);
  };

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <Typography variant="brandonTitle" component="h6">
          {heading}
        </Typography>
        {tooltip && (
          <ToolTip title={tooltip || ""} placement="top">
            <QuestionMarkIcon fontSize="small" sx={{ mb: 1 }} />
          </ToolTip>
        )}
      </Box>

      {subHeadingEl}

      <RadioGroup
        aria-label={heading}
        name="single-radio-select"
        value={value || null}
        onChange={handleChange}
        onFocus={onFocus}
      >
        {options?.map((option) => {
          // Hide the option if it matches one of the hiddenOptionIds
          if (hiddenOptionIds && hiddenOptionIds.includes(option.id)) {
            return null;
          }

          return (
            <RadioSelect
              hasError={hasError}
              key={option.label}
              value={option.id}
              label={option.label}
              description={option?.description || ""}
              name={option.label}
              tooltip={option?.tooltip || ""}
            />
          );
        })}
      </RadioGroup>
    </>
  );
};

export default SingleRadioSelect;

SingleRadioSelect.propTypes = {
  heading: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number,
  subHeadingEl: PropTypes.element,
  hasError: PropTypes.bool,
  onFocus: PropTypes.func,
  tooltip: PropTypes.string,
  hiddenOptionIds: PropTypes.array,
};
