import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Grid } from "@mui/material";
import { Formik } from "formik";
import delay from "delay";
import queryString from "query-string";

import axios from "src/utils/axios";
import submitToGADataLayer from "src/utils/submitToGADataLayer";
import validationSchema from "./validationSchema";
import { AGE_QUALIFICATIONS, TTC_QUALIFICATIONS } from "src/utils/constants";
import ReCAPTCHA from "react-google-recaptcha";
import { camelCase } from "lodash";

import {
  MultiSelect,
  PhoneInput,
  SingleRadioSelect,
  SubmitButton,
  TextInput,
  HiddenLogicComponent,
  ContentWrapper,
  Heading,
  FormWrapper,
  ErrorBanner,
  RobynDisclaimer,
  RobynFerringDisclaimer,
} from "src/components/Forms/common";
import { Typography } from "@mui/material";
import SingleSelect from "src/components/common/SingleSelect";

const defaultValues = {
  // Personal Information:
  firstName: "",
  email: "",
  phoneNumber: "",
  zipCode: "",
  // Journey:
  journeyPathStatus: null,
  // IS SEEING RE:
  atReStatus: {
    id: null,
    label: "",
    notAtReReasons: [],
    otherNotAtReReasonContent: "",
  },
  // Trying to Conceive Duration:
  ttcDuration: {
    id: null,
    label: "",
  },
  journeyEvent: {
    id: null,
    label: "",
    atReStatusId: null,
    otherContent: "",
  },
  ageRange: {
    id: null,
    label: "",
  },
  relationshipStatusId: null,
  atObgynStatusId: null,
  hasInsurance: {
    id: null,
    label: "",
  },
  hasInsuranceBenefitId: null,
  heightFeet: "-",
  heightInches: "-",
  weight: "",
  ethnicIdentities: [],
  genderIdentity: {
    id: null,
    label: "",
  },
  // Source:
  referralId: null,
  otherReferral: "",
  // Optin:
  optinId: null,
  // Terms and Conditions:
  tAndCId: null,
  // params:
  params: {
    source: null,
    medium: null,
    campaign: null,
    term: null,
    content: null,
    signupSource: null,
    signupUrl: null,
  },
  recaptcha: null,
};

const initialFormOptions = {
  heightFeet: [
    {
      value: "-",
      label: "-",
    },
    {
      value: 3,
      label: "3",
    },
    {
      value: 4,
      label: "4",
    },
    {
      value: 5,
      label: "5",
    },
    {
      value: 6,
      label: "6",
    },
    {
      value: 7,
      label: "7",
    },
  ],
  heightInches: [
    {
      value: "-",
      label: "-",
    },
    {
      value: 0,
      label: "0",
    },
    {
      value: 1,
      label: "1",
    },
    {
      value: 2,
      label: "2",
    },
    {
      value: 3,
      label: "3",
    },
    {
      value: 4,
      label: "4",
    },
    {
      value: 5,
      label: "5",
    },
    {
      value: 6,
      label: "6",
    },
    {
      value: 7,
      label: "7",
    },
    {
      value: 8,
      label: "8",
    },
    {
      value: 9,
      label: "9",
    },
    {
      value: 10,
      label: "10",
    },
    {
      value: 11,
      label: "11",
    },
  ],
  reCheck: [
    {
      id: 1,
      label:
        "I was seeing a fertility specialist, but am no longer seeing them ",
    },
    {
      id: 2,
      label: "I haven't seen a fertility specialist yet",
    },
  ],
  optin: [
    {
      id: 1,
      label: "Yes, please!",
    },
    {
      id: 2,
      label: "No, thank you.",
    },
  ],
  tAndC: [
    {
      id: 1,
      label: "I accept",
    },
    {
      id: 2,
      label: "I don't accept",
    },
  ],
};

const getFormOptions = async () => {
  try {
    const { data: formOptions } = await axios.get("/api/crm/form-options", {
      params: {
        formName: "signup",
      },
    });
    return formOptions;
  } catch (error) {
    throw error;
  }
};

const Form = ({ search }) => {
  const [formStatus, setFormStatus] = useState("IS_LOADING");
  const history = useHistory();
  const [formOptions, setFormOptions] = useState({});
  const [initialValues, setInitialValues] = useState(defaultValues);

  const handleSubmit = async ({ params, ...values }) => {
    try {
      setFormStatus("IS_LOADING");

      const isAgeQualified = AGE_QUALIFICATIONS.includes(values.ageRange.label);
      const isTTCQualified = TTC_QUALIFICATIONS.includes(
        values.ttcDuration.label
      );

      const isQualified = isAgeQualified && isTTCQualified;

      const isTest =
        values.firstName.toLowerCase().includes("test") ||
        process.env.REACT_APP_ENV === "staging";

      const isOptin = values.optinId === 1;

      const adjustedValues = {
        ...values,
        isOptin,
        isQualified,
        isTest,
        hasInsuranceId: values.hasInsurance.id,
        ageRangeId: values.ageRange.id,
        atObgynStatusId: values.atObgynStatusId,
        journeyPathStatusId: values.journeyPathStatus?.id,
        ...params,
      };

      const user = await axios.post("/api/clients/sign-up", adjustedValues);

      submitToGADataLayer({
        event: "Purchase",
        userId: user.id,
        isTest,
      });

      await delay(2000);

      if (isQualified) {
        submitToGADataLayer({
          event: "Qualified Client",
          isTest,
        });
      }

      // Widget Support: Redirect to Thank You page with signup source
      const widgetQueryString = params?.signupSource?.includes("fol")
        ? `?signup_source=${params.signupSource}`
        : "";

      history.push(`/thank-you${widgetQueryString}`);
    } catch (error) {
      console.error("error on submit", error.message);
      if (error.response.status === 409) {
        setFormStatus("PHONE_NUMBER_IN_USE_ERROR");
        return;
      }
      setFormStatus("SUBMIT_ERROR");
    }
  };

  const handleFieldCompleted = async (fieldName) => {
    submitToGADataLayer({
      event: "form_field_completed",
      formFieldName: fieldName,
    });
  };

  useEffect(() => {
    (async () => {
      try {
        const newFormOptions = await getFormOptions();

        const currentFormOptions = {
          ...initialFormOptions,
          ...newFormOptions,
        };
        setFormOptions(currentFormOptions);
      } catch (error) {
        console.log(error.message);
        setFormStatus("LOAD_ERROR");
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!formOptions.journeyPathStatus) return;

    const params = queryString.parse(search);

    const journeyPathParamOptions = {
      ttc: "Trying to Conceive",
      eggFreezing: "Egg Freezing",
    };

    const journeyPathParam =
      journeyPathParamOptions[camelCase(params?.journey_path)];
    const journeyPathStatus = formOptions.journeyPathStatus.find(
      (option) => option.label === journeyPathParam
    );

    setInitialValues((prevValues) => ({
      ...prevValues,
      journeyPathStatus: journeyPathStatus || null,
      params: {
        source: params?.utm_source,
        medium: params?.utm_medium,
        campaign: params?.utm_campaign,
        term: params?.utm_term,
        content: params?.utm_content,
        signupSource: params?.signup_source || "for",
        signupUrl: params?.signup_url || window.location.href,
        hasjourneyPathStatus: !!journeyPathStatus,
      },
    }));

    setFormStatus("SHOW_FORM");
  }, [initialValues.params.hasjourneyPathStatus, formOptions, search]);

  return (
    <ContentWrapper>
      <FormWrapper formName="sign-up" formStatus={formStatus}>
        <>
          <Heading
            title={"Sign up to text!"}
            subtitle="To help personalize the experience with your fertility coach, we
                just need a little more information from you."
          />

          <Formik
            validateOnBlur={false}
            validateOnChange={false}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              values,
              setFieldValue,
              errors,
              handleSubmit,
              setFieldError,
              isValid,
            }) => {
              const setField = (fieldName, value) => {
                setFieldValue(fieldName, value);
                setFieldError(fieldName, undefined);
              };

              const clearFields = (fieldNames) => {
                fieldNames.forEach((fieldName) => {
                  setFieldValue(fieldName, initialValues[fieldName]);
                  setFieldError(fieldName, undefined);
                });
              };

              return (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={2} sx={{ gap: "2.125rem" }}>
                    <Grid container spacing={2} item>
                      {/* First Name */}
                      <Grid item xs={12}>
                        <TextInput
                          label="First Name"
                          name="firstName"
                          value={values.firstName}
                          error={Boolean(errors.firstName)}
                          onChange={(e) => {
                            setField("firstName", e.target.value);
                          }}
                          onBlur={() => handleFieldCompleted("firstName")}
                          errorText={errors.firstName}
                          variant="form"
                        />
                      </Grid>
                      {/* Email */}
                      <Grid item xs={12}>
                        <TextInput
                          label="Email Address"
                          name="email"
                          value={values.email}
                          onChange={(e) => {
                            setField("email", e.target.value);
                          }}
                          error={Boolean(errors.email)}
                          onBlur={() => handleFieldCompleted("email")}
                          errorText={errors.email}
                          variant="form"
                        />
                      </Grid>
                      {/* PHONE */}
                      <Grid item xs={12}>
                        <PhoneInput
                          fullWidth
                          label="Mobile Number"
                          name="phoneNumber"
                          value={values?.phoneNumber}
                          onChange={(phoneNumber) => {
                            setField("phoneNumber", phoneNumber);
                          }}
                          errorText={errors.phoneNumber}
                          error={Boolean(errors.phoneNumber)}
                          onBlur={() => handleFieldCompleted("phoneNumber")}
                          variant="form"
                        />
                      </Grid>
                      {/* ZIP Code */}
                      <Grid item xs={12}>
                        <TextInput
                          variant="form"
                          label="ZIP Code"
                          name="zipCode"
                          value={values.zipCode}
                          error={Boolean(errors.zipCode)}
                          errorText={errors.zipCode}
                          onChange={(e) => {
                            // Remove any non-numeric characters

                            const zipCode = e.target.value.replace(/\D/g, "");
                            if (zipCode.length > 5) return;
                            setField("zipCode", zipCode);
                          }}
                          onBlur={() => {
                            handleFieldCompleted("zipCode");
                          }}
                        />
                      </Grid>
                    </Grid>
                    {/* Journey Path */}
                    {!values.params.hasjourneyPathStatus && (
                      <Grid item xs={12}>
                        <SingleRadioSelect
                          heading="What does your fertility journey look like?"
                          value={values.journeyPathStatus?.id}
                          options={formOptions.journeyPathStatus}
                          onChange={(path) => {
                            setField("journeyPathStatus", path);
                            handleFieldCompleted("journeyPathStatus");
                          }}
                          hasError={Boolean(errors.journeyPathStatus)}
                        />
                      </Grid>
                    )}
                    {/* CONDITIONAL: IF "TTC" was selected for 'What does your fertility treatment journey look like?' */}
                    <HiddenLogicComponent
                      value={
                        values.journeyPathStatus?.label === "Trying to Conceive"
                      }
                      clearFields={clearFields}
                      clearFieldNames={[
                        "atReStatus",
                        "journeyEvent",
                        "ttcDuration",
                      ]}
                    >
                      {/* FIELD: Has RE */}
                      <Grid item xs={12}>
                        <SingleRadioSelect
                          heading="Are you currently seeing a fertility specialist (reproductive endocrinologist/RE)?"
                          value={values.atReStatus?.id}
                          options={formOptions.atReStatus}
                          onChange={(status) => {
                            setField("atReStatus", {
                              ...values.atReStatus,
                              ...status,
                            });

                            handleFieldCompleted("atReStatus");
                          }}
                          hasError={Boolean(errors.atReStatus)}
                        />
                      </Grid>

                      {/* CONDITIONAL: IF "Yes" was selected for Are you currently seeing a fertility specialist */}
                      <HiddenLogicComponent
                        value={values.atReStatus?.label === "Yes"}
                        clearFields={clearFields}
                        clearFieldNames={["journeyEvent"]}
                      >
                        <Grid item xs={12}>
                          <SingleRadioSelect
                            heading="Where are you on the fertility treatment journey?"
                            value={values.journeyEvent?.id}
                            onChange={({ id, label }) => {
                              setField("journeyEvent", {
                                ...values.journeyEvent,
                                id,
                                label,
                              });
                              handleFieldCompleted("journeyEvent");
                              setFieldError("journeyEvent.label", undefined);
                            }}
                            // Form Options Journey Update Event could be written as journeyEvent[values.journeyPathStatusLabel]
                            // however, we are separating these fields so we do not need to create a CMS file for each journey path
                            options={formOptions.journeyEvent.ttc}
                            hasError={Boolean(errors.journeyEvent)}
                          />
                        </Grid>

                        {/* CONDITIONAL: IF "Other" was selected for Fertility Treatment Journey */}
                        <HiddenLogicComponent
                          value={values.journeyEvent?.label === "Other"}
                          clearFields={clearFields}
                          clearFieldNames={["journeyEvent.otherContent"]}
                        >
                          {/* FIELD: Other Fertility Treatment Journey (otherJourneyEventContent) */}
                          <Grid item xs={12}>
                            <TextInput
                              label="Please tell us where you are on the fertility treatment journey"
                              name="journeyEventOtherContent"
                              value={values.journeyEvent?.otherContent || ""}
                              onChange={(e) => {
                                const content = e.target.value;
                                setField("journeyEvent", {
                                  ...values.journeyEvent,
                                  otherContent: content,
                                });
                                setFieldError(
                                  "journeyEvent.otherContent",
                                  undefined
                                );
                              }}
                              error={Boolean(errors.journeyEvent?.otherContent)}
                              variant="form"
                              onBlur={() =>
                                handleFieldCompleted(
                                  "journeyEvent.otherContent"
                                )
                              }
                            />
                          </Grid>
                        </HiddenLogicComponent>
                      </HiddenLogicComponent>
                      {/* CLOSURE: IF "Yes" was selected for Are you currently seeing a fertility specialist */}

                      {/* CONDITIONAL: If "No" was selected for Are you currently Seeing a fertility specialist */}
                      <HiddenLogicComponent
                        value={values.atReStatus?.label === "No"}
                        clearFields={clearFields}
                        clearFieldNames={["atReStatus.notAtReReasons"]}
                      >
                        {/* FIELD: Not At RE Reason */}
                        <Grid item xs={12}>
                          <MultiSelect
                            heading="Why are you not seeing a fertility specialist?"
                            onChange={(notAtReReasons) => {
                              setField("atReStatus", {
                                ...values.atReStatus,
                                notAtReReasons,
                              });
                              setFieldError(
                                "atReStatus.notAtReReasons",
                                undefined
                              );
                              handleFieldCompleted("notAtReReasons");
                            }}
                            values={values.atReStatus?.notAtReReasons}
                            options={formOptions.ttcNotAtReReasons}
                            hasError={Boolean(
                              errors.atReStatus?.notAtReReasons
                            )}
                          />
                        </Grid>
                        {/* CONDITIONAL: IF "Other" is selected for Not At RE Reason */}
                        <HiddenLogicComponent
                          value={values.atReStatus?.notAtReReasons?.some(
                            (reason) => reason.label === "Other"
                          )}
                          clearFields={clearFields}
                          clearFieldNames={[
                            "atReStatus.otherNotAtReReasonContent",
                          ]}
                        >
                          {/* FIELD: Other Not At RE Reason */}
                          <Grid item xs={12}>
                            <TextInput
                              label="Please tell us why you are not seeing a fertility specialist"
                              name="otherNotAtReReasonContent"
                              value={
                                values.atReStatus?.otherNotAtReReasonContent
                              }
                              onChange={(e) => {
                                const content = e.target.value;
                                setField("atReStatus", {
                                  ...values.atReStatus,
                                  otherNotAtReReasonContent: content,
                                });
                                setFieldError(
                                  "atReStatus.otherNotAtReReasonContent",
                                  undefined
                                );
                              }}
                              error={Boolean(
                                errors.atReStatus?.otherNotAtReReasonContent
                              )}
                              variant="form"
                              onBlur={() =>
                                handleFieldCompleted(
                                  "otherNotAtReReasonContent"
                                )
                              }
                            />
                          </Grid>
                        </HiddenLogicComponent>
                        {/* CLOSURE: IF "Other" is selected for Not At RE Reason */}
                      </HiddenLogicComponent>
                      {/* CLOSURE: If "No" was selected for Are you currently Seeing a fertility specialist */}
                      {/* FIELD: Trying To Conceive Duration (ttcDuration)  */}
                      <Grid item xs={12}>
                        <SingleRadioSelect
                          heading="How long have you been trying to conceive?"
                          value={values.ttcDuration.id}
                          onChange={(ttcDuration) => {
                            setField("ttcDuration", ttcDuration);
                            handleFieldCompleted("ttcDuration");
                          }}
                          options={formOptions.ttcDuration}
                          hasError={Boolean(errors.ttcDuration)}
                        />
                      </Grid>
                    </HiddenLogicComponent>
                    {/* CLOSE: IF "TTC" was selected for 'What does your fertility treatment journey look like?' */}

                    {/* CONDITIONAL: IF "Egg Freezing" was selected for 'What does your fertility treatment journey look like?' */}
                    <HiddenLogicComponent
                      value={values.journeyPathStatus?.label === "Egg Freezing"}
                      clearFields={clearFields}
                      clearFieldNames={["atReStatus", "journeyEvent"]}
                    >
                      {/* FIELD: Has RE */}
                      <Grid item xs={12}>
                        <SingleRadioSelect
                          heading="Are you currently seeing a fertility specialist (reproductive endocrinologist/RE)?"
                          value={values.atReStatus?.id}
                          options={formOptions.atReStatus}
                          onChange={(status) => {
                            setField("atReStatus", {
                              ...values.atReStatus,
                              ...status,
                            });
                            handleFieldCompleted("atReStatus");
                          }}
                          hasError={Boolean(errors.atReStatus)}
                        />
                      </Grid>

                      {/* CONDITIONAL: IF "Yes" was selected for 'Are you currently seeing a fertility specialist (reproductive endocrinologist/RE)?' */}
                      <HiddenLogicComponent
                        value={values.atReStatus?.label === "Yes"}
                        clearFields={clearFields}
                        clearFieldNames={["journeyEvent"]}
                      >
                        {/* FIELD: Egg Freezing Journey (journeyEvent) */}
                        <Grid item xs={12}>
                          <SingleRadioSelect
                            heading="Where are you on the egg freezing journey?"
                            value={values.journeyEvent?.id}
                            onChange={({ id, label }) => {
                              setField("journeyEvent", {
                                ...values.journeyEvent,
                                id,
                                label,
                              });
                              setFieldError("journeyEvent", undefined);
                              handleFieldCompleted("journeyEvent");
                            }}
                            options={formOptions.journeyEvent.eggFreezing}
                            hasError={Boolean(errors.journeyEvent)}
                          />
                        </Grid>

                        {/* CONDITIONAL: IF "Other" was selected for Egg Freezing Journey */}
                        <HiddenLogicComponent
                          value={values.journeyEvent?.label === "Other"}
                          clearFields={clearFields}
                          clearFieldNames={["journeyEvent.otherContent"]}
                        >
                          {/* FIELD: Other Egg Freezing Journey (journeyEventOtherContent) */}
                          <Grid item xs={12}>
                            <TextInput
                              label="Please tell us where you are on your egg freezing journey"
                              name="journeyEventOtherContent"
                              value={values.journeyEvent?.otherContent}
                              onChange={(e) => {
                                const content = e.target.value;
                                setField("journeyEvent", {
                                  ...values.journeyEvent,
                                  otherContent: content,
                                });
                                setFieldError(
                                  "journeyEventOtherContent",
                                  undefined
                                );
                              }}
                              error={Boolean(errors.journeyEvent?.otherContent)}
                              variant="form"
                              onBlur={() =>
                                handleFieldCompleted("journeyEventOtherContent")
                              }
                            />
                          </Grid>
                        </HiddenLogicComponent>
                        {/* CLOSURE: IF "Other" was selected for Egg Freezing Journey */}
                      </HiddenLogicComponent>
                      {/* CLOSURE: IF "Yes" was selected for 'Are you currently seeing a fertility specialist (reproductive endocrinologist/RE)?' */}

                      {/* CONDITIONAL: IF "No" was selected for 'Are you currently seeing a fertility specialist (reproductive endocrinologist/RE)?' */}
                      <HiddenLogicComponent
                        value={values.atReStatus?.label === "No"}
                        clearFields={clearFields}
                        clearFieldNames={["atReStatus.notAtReReasons"]}
                      >
                        {/* FIELD: Not At RE Reason */}
                        <Grid item xs={12}>
                          <MultiSelect
                            heading="Why are you not seeing a fertility specialist?"
                            onChange={(notAtReReasons) => {
                              setField("atReStatus", {
                                ...values.atReStatus,
                                notAtReReasons,
                              });
                              setFieldError(
                                "atReStatus.notAtReReasons",
                                undefined
                              );
                              handleFieldCompleted("notAtReReason");
                            }}
                            values={values.atReStatus?.notAtReReasons}
                            options={formOptions.eggFreezingNotAtReReasons}
                            hasError={Boolean(
                              errors.atReStatus?.notAtReReasons
                            )}
                          />
                        </Grid>
                        {/* CONDITIONAL: IF "Other" is selected for Not At RE Reason */}
                        <HiddenLogicComponent
                          value={values.atReStatus?.notAtReReasons?.some(
                            (reason) => reason.label === "Other"
                          )}
                          clearFields={clearFields}
                          clearFieldNames={[
                            "atReStatus.otherNotAtReReasonContent",
                          ]}
                        >
                          {/* FIELD: Other Not At RE Reason */}
                          <Grid item xs={12}>
                            <TextInput
                              label="Please tell us why you are not seeing a fertility specialist"
                              name="otherNotAtReReasonContent"
                              value={
                                values.atReStatus?.otherNotAtReReasonContent
                              }
                              onChange={(e) => {
                                const content = e.target.value;
                                setField("atReStatus", {
                                  ...values.atReStatus,
                                  otherNotAtReReasonContent: content,
                                });
                                setFieldError(
                                  "atReStatus.otherNotAtReReasonContent",
                                  undefined
                                );
                              }}
                              error={Boolean(
                                errors.atReStatus?.otherNotAtReReasonContent
                              )}
                              variant="form"
                              onBlur={() =>
                                handleFieldCompleted(
                                  "otherNotAtReReasonContent"
                                )
                              }
                            />
                          </Grid>
                        </HiddenLogicComponent>
                        {/* CLOSURE: IF "Other" is selected for Not At RE Reason */}
                      </HiddenLogicComponent>
                      {/* CLOSURE: IF "No" was selected for 'Are you currently seeing a fertility specialist (reproductive endocrinologist/RE)?' */}
                    </HiddenLogicComponent>
                    {/* CLOSE: IF "Egg Freezing" was selected for 'What does your fertility treatment journey look like?' */}

                    {/* AGE */}
                    <Grid item xs={12}>
                      <SingleRadioSelect
                        heading="Age"
                        value={values.ageRange.id}
                        onChange={(ageRange) => {
                          setField("ageRange", ageRange);
                          handleFieldCompleted("ageRange");
                        }}
                        options={formOptions.ageRange}
                        hasError={Boolean(errors.ageRange)}
                      />
                    </Grid>

                    {/* GENDER IDENTITY */}
                    <Grid item xs={12}>
                      <SingleRadioSelect
                        heading="Gender Identity"
                        value={values.genderIdentity.id}
                        onChange={(genderIdentity) => {
                          setField("genderIdentity", genderIdentity);
                          handleFieldCompleted("genderIdentity");
                        }}
                        options={formOptions.genderIdentity}
                        hasError={Boolean(errors.genderIdentity)}
                      />
                    </Grid>

                    {/* RELATIONSHIP STATUS */}
                    <Grid item xs={12}>
                      <SingleRadioSelect
                        heading="How would you best describe your relationship status?"
                        value={values.relationshipStatusId}
                        onChange={({ id }) => {
                          setField("relationshipStatusId", id);

                          handleFieldCompleted("relationshipStatus");
                        }}
                        options={formOptions.relationshipStatus}
                        hasError={Boolean(errors.relationshipStatusId)}
                      />
                    </Grid>

                    {/* ETHNIC IDENTITY */}
                    <Grid item xs={12}>
                      <MultiSelect
                        heading="Ethnic Identity"
                        values={values.ethnicIdentities}
                        onChange={(values) => {
                          setField("ethnicIdentities", values);
                          handleFieldCompleted("ethnicIdentity");
                        }}
                        options={formOptions.ethnicIdentity}
                        hasError={Boolean(errors.ethnicIdentities)}
                      />
                    </Grid>

                    {/*  OBGYN Status*/}
                    <Grid item xs={12}>
                      <SingleRadioSelect
                        heading="Do you have an OBGYN?"
                        value={values.atObgynStatusId}
                        onChange={({ id }) => {
                          setField("atObgynStatusId", id);
                          handleFieldCompleted("atObgynStatusId");
                        }}
                        options={formOptions.atObgynStatus}
                        hasError={Boolean(errors.atObgynStatusId)}
                      />
                    </Grid>

                    {/* HAS INSURANCE */}
                    <Grid item xs={12}>
                      <SingleRadioSelect
                        heading="Are you insured?"
                        value={values.hasInsurance.id}
                        onChange={(value) => {
                          setField("hasInsurance", value);
                          handleFieldCompleted("hasInsurance");
                          setFieldError("hasInsuranceBenefitId", undefined);
                        }}
                        options={formOptions.hasInsurance}
                        hasError={Boolean(errors.hasInsurance)}
                      />
                    </Grid>

                    {/* CONDITIONAL: IF "Yes" is selected for  "Are you insured?" */}
                    <HiddenLogicComponent
                      value={values.hasInsurance?.label === "Yes"}
                      clearFields={clearFields}
                      clearFieldNames={["hasInsuranceBenefitId"]}
                    >
                      {/* HAS INSURANCE BENEFIT */}
                      <Grid item xs={12}>
                        <SingleRadioSelect
                          heading="Does your insurance have fertility benefits?"
                          value={values.hasInsuranceBenefitId}
                          onChange={({ id }) => {
                            setField("hasInsuranceBenefitId", id);
                            setFieldError("hasInsuranceBenefitId", undefined);

                            handleFieldCompleted("hasInsuranceBenefit");
                          }}
                          options={formOptions.hasInsuranceBenefit}
                          hasError={Boolean(errors.hasInsuranceBenefitId)}
                        />
                      </Grid>
                    </HiddenLogicComponent>
                    {/* CLOSURE: IF "Yes" is selected for  "Are you insured?" */}

                    <Grid container item spacing={3}>
                      <Grid item xs={12}>
                        <Typography
                          variant="brandonTitle"
                          sx={{
                            mb: 3,
                          }}
                        >
                          Your height?
                        </Typography>
                      </Grid>

                      <Grid item container xs={6}>
                        <Typography variant="brandonTitle">Feet</Typography>
                        <SingleSelect
                          id="heightFeet"
                          value={values.heightFeet}
                          options={formOptions.heightFeet}
                          onChange={(value) => {
                            setField("heightFeet", value);
                            setFieldError("heightFeet", undefined);
                          }}
                          variant="outlined"
                          hasError={Boolean(errors.heightFeet)}
                          hasWhiteBackground
                          sx={{
                            borderRadius: "0",
                            "& .MuiOutlinedInput-notchedOutline": {
                              border: "none",
                            },
                          }}
                        />
                      </Grid>
                      <Grid item container xs={6}>
                        <Typography variant="brandonTitle">Inches</Typography>
                        <SingleSelect
                          id="heightInches"
                          value={values.heightInches}
                          options={formOptions.heightInches}
                          onChange={(value) => {
                            setField("heightInches", value.toString());
                            setFieldError("heightInches", undefined);
                          }}
                          variant="outlined"
                          hasError={Boolean(errors.heightInches)}
                          hasWhiteBackground
                          sx={{
                            borderRadius: "0",
                            "& .MuiOutlinedInput-notchedOutline": {
                              border: "none",
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextInput
                          label="Your weight in lbs?"
                          name="weight"
                          value={values.weight}
                          onChange={(e) => {
                            const weight = e.target.value.replace(/\D/g, "");
                            if (weight.length > 3) return;
                            setField("weight", weight);
                            setFieldError("weight", undefined);
                          }}
                          error={Boolean(errors.weight)}
                          errorText={errors.weight}
                          variant="form"
                        />
                      </Grid>
                    </Grid>

                    {/* HOW DID YOU HEAR ABOUT US?  */}
                    <Grid item xs={12}>
                      <SingleRadioSelect
                        heading="How did you hear about us?"
                        value={values.referralId}
                        onChange={({ id }) => {
                          setField("referralId", id);

                          handleFieldCompleted("referralId");
                        }}
                        options={formOptions.referral}
                        hasError={Boolean(errors.referralId)}
                      />
                    </Grid>
                    <HiddenLogicComponent
                      clearFields={clearFields}
                      value={values.referralId === 9}
                      clearFieldNames={["otherReferral"]}
                    >
                      <Grid item xs={12}>
                        <TextInput
                          label="Please tell us how you heard about us"
                          name="otherReferral"
                          value={values.otherReferral}
                          onChange={(e) => {
                            setField("otherReferral", e.target.value);
                          }}
                          error={Boolean(errors.otherReferral)}
                          variant="form"
                          onBlur={() => handleFieldCompleted("otherReferral")}
                        />
                      </Grid>
                    </HiddenLogicComponent>
                    {/* OPTIN TO UPDATES */}
                    <Grid item xs={12}>
                      <SingleRadioSelect
                        heading="Would you like to receive updates and information via email?"
                        value={values.optinId}
                        onChange={({ id }) => {
                          setField("optinId", id);

                          handleFieldCompleted("optin");
                        }}
                        options={formOptions.optin}
                        hasError={Boolean(errors.optinId)}
                      />
                    </Grid>
                    {/* IF opted in or out of Updates, show Terms and Conditions: */}
                    <HiddenLogicComponent
                      value={values.optinId}
                      clearFields={clearFields}
                      clearFieldNames={["tAndCId"]}
                    >
                      {values.optinId === 1 ? (
                        <Grid item xs={12}>
                          <SingleRadioSelect
                            heading="Terms & Conditions"
                            subHeadingEl={<RobynFerringDisclaimer />}
                            value={values.tAndCId}
                            onChange={({ id }) => {
                              setField("tAndCId", id);

                              handleFieldCompleted("tAndC");
                            }}
                            options={formOptions.tAndC}
                            hasError={Boolean(errors.tAndCId)}
                          />
                        </Grid>
                      ) : (
                        <Grid item xs={12}>
                          <SingleRadioSelect
                            heading="Terms & Conditions"
                            subHeadingEl={<RobynDisclaimer />}
                            value={values.tAndCId}
                            onChange={({ id }) => {
                              setField("tAndCId", id);

                              handleFieldCompleted("tAndC");
                            }}
                            options={formOptions.tAndC}
                            hasError={Boolean(errors.tAndCId)}
                          />
                        </Grid>
                      )}
                    </HiddenLogicComponent>

                    {process.env.REACT_APP_RECAPTCHA_SITE_KEY && (
                      <Grid item xs={12} container justifyContent="center">
                        <ReCAPTCHA
                          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                          onChange={(token) => {
                            setFieldValue("recaptcha", token);
                            setFieldError("recaptcha", undefined);
                          }}
                        />
                      </Grid>
                    )}

                    {!isValid && (
                      <Grid item xs={12}>
                        <ErrorBanner />
                      </Grid>
                    )}

                    <Grid item container justifyContent="center" xs={12}>
                      <SubmitButton disabled={!isValid} />
                    </Grid>
                  </Grid>
                </form>
              );
            }}
          </Formik>
        </>
      </FormWrapper>
    </ContentWrapper>
  );
};

export default Form;
