import { useState, useContext } from "react";
import { Formik } from "formik";
import {
  Button,
  IconButton,
  InputAdornment,
  SvgIcon,
  TextField,
  Stack,
  Typography,
  Menu as FilterMenu,
  Checkbox,
  Grid,
} from "@mui/material";
import PhoneInput from "src/components/Forms/common/PhoneInput";
import {
  Search as SearchIcon,
  Phone as PhoneIcon,
  User as UserIcon,
} from "react-feather";
import {
  CloseRounded,
  FilterList as FilterListIcon,
} from "@mui/icons-material";
import AgentSelect from "src/components/common/AgentSelect";
import { OptionContext } from "src/context/OptionContext";
import { ClientsContext } from "src/context/ClientsContext";
import MultiSelectDropdown from "src/components/common/MultiSelect";
import SingeSelectAutocomplete from "src/components/common/SingleSelectAutocomplete";

const Filters = () => {
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false);
  const {
    ageRangeOptions,
    activePhysicianOptions,
    currentTreatmentOptions,
    tagOptions,
  } = useContext(OptionContext);

  const { setFilters, resetFilters, filters } = useContext(ClientsContext);

  const handleSubmitFilters = (filters) => {
    setFilters(filters);
    setIsFilterMenuOpen(false);
  };

  const handleClearFilters = () => {
    resetFilters();
    setIsFilterMenuOpen(false);
  };

  const checkboxFilters = [
    {
      id: "is-clients",
      name: "isActive",
      label: "Active Clients",
    },
    {
      id: "is-qualified",
      name: "isQualified",
      label: "Is Qualified",
    },
    {
      id: "is-engaged-user",
      name: "isEngaged",
      label: "Engaged User",
    },
    {
      id: "has-insurance",
      name: "hasInsurance",
      label: "Has Insurance",
    },
    {
      id: "has-bmi-under-40",
      name: "hasBmiUnder40",
      label: "BMI Under 40",
    },
    {
      id: "has-virtual-session",
      name: "hasVirtualSession",
      label: "Has Virtual Session",
    },
    {
      id: "has-booked-virtual-session",
      name: "hasBookedVirtualSession",
      label: "Booked Virtual Session",
    },
    {
      id: "has-completed-virtual-session",
      name: "hasCompletedVirtualSession",
      label: "Completed Virtual Session",
    },
    {
      id: "has-completed-intake-form",
      name: "hasCompletedIntakeForm",
      label: "Completed Intake Form",
    },
    {
      id: "has-completed-follow-up-form",
      name: "hasCompletedFollowUpForm",
      label: "Completed Follow Up Form",
    },
    {
      id: "has-not-receieved-message-from-coach",
      name: "hasNotReceivedCoachMessage",
      label: "Not Received Coach Message",
    },
  ];

  return (
    <>
      <IconButton
        aria-label="Filters"
        id="filter-button"
        color={"primary"}
        aria-controls={isFilterMenuOpen ? "filter-menu" : undefined}
        aria-expanded={isFilterMenuOpen ? "true" : undefined}
        aria-haspopup="true"
        onClick={() => setIsFilterMenuOpen(!isFilterMenuOpen)}
        sx={{
          width: (theme) => theme.spacing(7),
          height: (theme) => theme.spacing(7),
        }}
      >
        <FilterListIcon />
      </IconButton>

      <FilterMenu
        id="filter-menu"
        MenuListProps={{ "aria-labelledby": "filter-button" }}
        anchorEl={document.getElementById("filter-button")}
        open={isFilterMenuOpen}
        onClose={() => setIsFilterMenuOpen(false)}
        sx={{
          "& .MuiPaper-root": {
            width: "90%",
            maxWidth: "600px",
          },
          zIndex: 1,
        }}
      >
        <Formik
          enableReinitialize
          initialValues={filters}
          onSubmit={handleSubmitFilters}
        >
          {({ values, handleChange, setFieldValue, handleSubmit }) => {
            const selectedTags = tagOptions.filter((tag) =>
              values.tagIds.includes(tag.id)
            );

            const selectedActivePhysicians = activePhysicianOptions.filter(
              (physician) => values.activePhysicianIds.includes(physician.id)
            );

            const selectedCurrentTreatments = currentTreatmentOptions.filter(
              (treatment) => values.currentTreatmentIds.includes(treatment.id)
            );

            const handleSetMultiSelectFilter = ({ filterId, values }) => {
              const valueIds = values.map((value) => value.id);
              setFieldValue(filterId, valueIds);
            };

            return (
              <form
                onSubmit={(e) => {
                  handleSubmit(e);
                }}
              >
                <Stack sx={{ m: 2 }} useFlexGap spacing={2}>
                  <Stack
                    sx={{
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    direction="row"
                  >
                    <Typography variant="h4">Filters</Typography>
                    <IconButton onClick={() => setIsFilterMenuOpen(false)}>
                      <CloseRounded />
                    </IconButton>
                  </Stack>
                  {/* Filter Textfields Below */}
                  <Grid container spacing={2}>
                    {/* DISPLAY NAME: */}
                    <Grid item xs={12} lg={6}>
                      <TextField
                        fullWidth
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SvgIcon fontSize="small">
                                <SearchIcon />
                              </SvgIcon>
                            </InputAdornment>
                          ),
                        }}
                        name="displayNameKeyword"
                        onChange={handleChange}
                        placeholder="Display Name"
                        value={values.displayNameKeyword}
                        variant="outlined"
                      />
                    </Grid>
                    {/* Client ID */}
                    <Grid item xs={12} lg={6}>
                      <TextField
                        fullWidth
                        type="number"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SvgIcon fontSize="small">
                                <UserIcon />
                              </SvgIcon>
                            </InputAdornment>
                          ),
                        }}
                        name="clientId"
                        onChange={handleChange}
                        placeholder="Client ID#"
                        value={values.clientId}
                        variant="outlined"
                      />
                    </Grid>
                    {/* PHONE NUMBER */}
                    <Grid item xs={12} lg={6}>
                      <PhoneInput
                        fullWidth
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SvgIcon fontSize="small">
                                <PhoneIcon />
                              </SvgIcon>
                            </InputAdornment>
                          ),
                        }}
                        inputComponent="mui-textfield"
                        value={values.phoneNumber}
                        onChange={(value) => {
                          setFieldValue("phoneNumber", value);
                        }}
                        name="phoneNumber"
                        placeholder={`Phone Number`}
                        variant="outlined"
                      />
                    </Grid>
                    {/* Filter Dropdowns Below */}
                    {/* AGENT SELECT */}
                    <Grid item xs={12} lg={6}>
                      <AgentSelect
                        initialAgent={values.agent}
                        handleSetAgent={(agent) => {
                          setFieldValue("agent", agent);
                        }}
                      />
                    </Grid>
                    {/* AGE RANGE SELECT */}
                    <Grid item xs={12} lg={6}>
                      <SingeSelectAutocomplete
                        id="ageRangeId"
                        label="Age Range"
                        options={ageRangeOptions}
                        value={values.ageRangeId}
                        onChange={(value) => {
                          setFieldValue("ageRangeId", value);
                        }}
                      />
                    </Grid>
                    {/* ACTIVE PHYSICIANS MULTISELECT */}
                    <Grid item xs={12} lg={6}>
                      <MultiSelectDropdown
                        id="activePhysicianIds"
                        label="Active Physicians"
                        options={activePhysicianOptions}
                        values={selectedActivePhysicians}
                        setValues={(values, filterId) => {
                          handleSetMultiSelectFilter({
                            filterId,
                            values,
                          });
                        }}
                      />
                    </Grid>
                    {/* CURRENT TREATMENTS MULTISELECT */}
                    <Grid item xs={12} lg={6}>
                      <MultiSelectDropdown
                        id="currentTreatmentIds"
                        label="Current Treatments"
                        options={currentTreatmentOptions}
                        values={selectedCurrentTreatments}
                        setValues={(values, filterId) => {
                          handleSetMultiSelectFilter({
                            filterId,
                            values,
                          });
                        }}
                      />
                    </Grid>
                    {/* TAGS */}
                    <Grid item xs={12} lg={6}>
                      <MultiSelectDropdown
                        id="tagIds"
                        label="Tags"
                        options={tagOptions}
                        values={selectedTags}
                        setValues={(values, filterId) => {
                          handleSetMultiSelectFilter({
                            filterId,
                            values,
                          });
                        }}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    {checkboxFilters.map((checkbox) => (
                      <Grid
                        key={checkbox.id}
                        item
                        container
                        alignItems="center"
                        justifyContent="space-between"
                        xs={12}
                        lg={6}
                      >
                        <Typography htmlFor={checkbox.id} variant="body1">
                          {checkbox.label}
                        </Typography>
                        <Checkbox
                          id={checkbox.id}
                          label={checkbox.label}
                          color="primary"
                          checked={values[checkbox.name] || false}
                          fontSize="large"
                          onChange={(e) => {
                            setFieldValue(checkbox.name, e.target.checked);
                          }}
                        />
                      </Grid>
                    ))}
                  </Grid>
                  {/* ACTION BUTTONS BELOW: */}
                  <Stack direction="row" useFlexGap spacing={2}>
                    <Button
                      fullWidth
                      variant="outlined"
                      onClick={() => handleClearFilters()}
                    >
                      Clear
                    </Button>
                    <Button
                      sx={{ height: "100%" }}
                      fullWidth
                      variant="contained"
                      color="primary"
                      type="submit"
                    >
                      Filter
                    </Button>
                  </Stack>
                </Stack>
              </form>
            );
          }}
        </Formik>
      </FilterMenu>
    </>
  );
};

export default Filters;
