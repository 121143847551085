/** @format */

import React from "react";
import PropTypes from "prop-types";
import {
  ListItem,
  Typography,
  Avatar,
  Tooltip,
  Stack,
  Box,
} from "@mui/material";
import moment from "moment";
import Profile from "./Profile";
import ThreadFlagMultiSelect from "src/components/common/ThreadFlagMultiSelect";
import ThreadChip from "src/components/Chat/ThreadChip";

const ThreadItem = ({
  active,
  thread,
  onSelect,
  selectedClientId,
  setSelectedClientId,
  ...rest
}) => {
  return (
    <>
      <ListItem
        divider
        sx={{
          position: "relative",
          backgroundColor: active ? "grey10" : "background.default",
          borderBottom: active && "none",
          cursor: "pointer",
          display: "inline-block",
          justifyContent: "flex-start",
          width: "100%",
          pl: 1.75,
        }}
        onClick={onSelect}
        {...rest}
      >
        {thread.hasUnreadClientMessageIds && (
          <Box
            sx={{
              backgroundColor: "error.main",
              width: 4,
              height: "85%",
              fontSize: 16,
              position: "absolute",
              left: 4,
              top: "50%",
              transform: "translateY(-50%)",
            }}
          />
        )}
        {/* ROW1 */}
        <Stack direction="row" sx={{ justifyContent: "space-between" }}>
          {/* ROW1, left */}
          <Stack
            direction="row"
            spacing={1}
            sx={{
              alignItems: "center",
              maxWidth: "59%",
            }}
          >
            <Typography
              variant="body2"
              sx={{
                color: "text.primary",
                ellipsis: true,
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {thread.displayName}{" "}
            </Typography>

            {thread.lastMessage && (
              <Typography
                variant="caption"
                sx={{
                  color: "text.secondary",
                }}
              >
                {moment(thread.lastMessage.createdAt).format("L")}
              </Typography>
            )}
          </Stack>
          {/* ROW1, Right */}
          <Stack
            direction="row"
            spacing={1}
            sx={{
              justifyContent: "flex-end",
              alignContent: "center",
              flex: 1,
              maxWidth: "40%",
            }}
          >
            {!thread.isActive && <ThreadChip label="INACTIVE" />}
            {thread.agent && (
              <Stack justifyContent="center">
                <Tooltip
                  title={`${thread.agent.firstName} ${thread.agent.lastName}`}
                  placement="top-end"
                >
                  <Avatar
                    alt=""
                    src={thread.agent.avatarLink}
                    size="small"
                    variant="circular"
                    sx={{
                      height: 26,
                      width: 26,
                      fontSize: 14,
                    }}
                  >
                    {thread.agent?.firstName?.[0]}
                    {thread.agent?.lastName?.[0]}
                  </Avatar>
                </Tooltip>
              </Stack>
            )}
            <Stack justifyContent="center">
              <Profile thread={thread} />
            </Stack>
          </Stack>
        </Stack>
        {/* ROW2 */}
        <Stack direction="row" spacing={1}>
          {thread.lastMessage && (
            <Typography
              variant="caption"
              sx={{
                color: "text.disabled",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {thread.lastMessage.body}
            </Typography>
          )}
        </Stack>
        {/* ROW3 */}
        <ThreadFlagMultiSelect
          clientId={JSON.stringify(thread.id)}
          clientThreadFlags={thread.threadFlags}
          selectedClientId={selectedClientId}
          setSelectedClientId={setSelectedClientId}
        />
      </ListItem>
    </>
  );
};

ThreadItem.propTypes = {
  active: PropTypes.bool,
  onSelect: PropTypes.func,
  thread: PropTypes.object.isRequired,
};

ThreadItem.defaultProps = {
  active: false,
  onSelect: () => {},
};

export default ThreadItem;
