import React, { useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Avatar,
  Box,
  Card,
  InputAdornment,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  colors,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Search as SearchIcon } from "react-feather";
import getInitials from "src/utils/getInitials";

const useStyles = makeStyles((theme) => ({
  root: {},
  queryField: {
    width: 500,
  },
  statusField: {
    flexBasis: 200,
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    color: colors.common.white,
  },
}));

const applyFilters = ({ coaches, query }) => {
  return coaches?.filter((user) => {
    let matches = true;

    if (query) {
      const properties = ["firstName", "lastName", "email"];
      let containsQuery = false;
      properties.forEach((property) => {
        if (user[property]?.toLowerCase().includes(query.toLowerCase())) {
          containsQuery = true;
        }
      });
      if (!containsQuery) {
        matches = false;
      }
    }

    return matches;
  });
};

const applyPagination = ({ coaches, page, limit }) => {
  return coaches.slice(page * limit, page * limit + limit);
};

const CoachesTable = ({ isAgentAdmin, coaches, setCoaches, ...rest }) => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [query, setQuery] = useState("");

  const handleQueryChange = (event) => {
    event.persist();
    const query = event.target.value;
    setQuery(query);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value));
  };

  let paginatedCoaches = [];
  let filteredCoaches = [];
  if (coaches) {
    filteredCoaches = applyFilters({ coaches, query });
    paginatedCoaches = applyPagination({
      coaches: filteredCoaches,
      page,
      limit,
    });
  }

  return (
    <>
      <Card className={clsx(classes.root)} {...rest}>
        <Box p={2}>
          <Box display="flex" alignItems="center">
            <TextField
              className={classes.queryField}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                ),
              }}
              onChange={handleQueryChange}
              placeholder="Search Coaches"
              value={query}
              variant="outlined"
            />
          </Box>
        </Box>

        <PerfectScrollbar>
          <Box minWidth={1200}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox"></TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedCoaches?.map((provider, index) => {
                  return (
                    <TableRow hover key={index}>
                      <TableCell padding="checkbox"></TableCell>
                      <TableCell>
                        <Box display="flex" alignItems="center">
                          <Avatar
                            className={classes.avatar}
                            src={provider.avatarLink}
                          >
                            {getInitials(
                              `${provider?.firstName} ${provider.lastName}`
                            )}
                          </Avatar>
                          <Box ml={2}>
                            {provider?.firstName} {provider.lastName}
                          </Box>
                        </Box>
                      </TableCell>

                      <TableCell>{provider.email}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        <TablePagination
          component="div"
          count={filteredCoaches.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </Card>
    </>
  );
};

CoachesTable.propTypes = {
  coaches: PropTypes.array.isRequired,
  setCoaches: PropTypes.func,
};

CoachesTable.defaultProps = {
  coaches: [],
};

export default CoachesTable;
