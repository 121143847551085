import { Chip, IconButton, Stack, Typography } from "@mui/material";
import { Edit as EditIcon } from "react-feather";
import ToolTip from "src/components/common/ToolTip";

const Status = ({ type, label, status, editStatusType, setEditStatusType }) => {
  const value = status?.label || null;

  const notAtReReasons = status?.notAtReReasons || [];
  const otherNotAtReReasonContent =
    notAtReReasons?.find((reason) => reason.label === "Other")
      ?.otherNotAtReReasonContent || "";

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
        useFlexGap
      >
        <Typography variant="caption" fontWeight="500">
          {label}
        </Typography>
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography variant="caption" noWrap>
            {value || "N/A"}
          </Typography>
          <IconButton
            color="primary"
            onClick={() => setEditStatusType(type)}
            disabled={Boolean(editStatusType)}
          >
            <EditIcon size="16px" />
          </IconButton>
        </Stack>
      </Stack>
      {Boolean(notAtReReasons.length > 0) && (
        <Stack
          alignItems="flex-end"
          sx={{ overflow: "hidden", maxWidth: "100%" }}
        >
          <Stack
            direction="row"
            spacing={0.5}
            alignItems="center"
            sx={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              maxWidth: "100%",
              px: 0.5,
            }}
          >
            {notAtReReasons.map((notAtReReason) => {
              const tooltipTitle =
                notAtReReason.label === "Other"
                  ? otherNotAtReReasonContent
                  : notAtReReason.label;
              return (
                <ToolTip key={notAtReReason.id} title={tooltipTitle}>
                  <Chip
                    key={notAtReReason.id}
                    label={notAtReReason.label}
                    sx={{
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      maxWidth: 100,
                    }}
                  />
                </ToolTip>
              );
            })}
          </Stack>
        </Stack>
      )}
    </>
  );
};

export default Status;
